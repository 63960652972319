


































































import JnJuranewsSearch from "@/views/search/JuranewsSearch.vue";
import JnCardsStrip from "@/views/common/CardsStrip.vue";
import JnSearchResults from "@/views/search/SearchResults.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { JnService } from "@/services/jn.service";
import { SearchData } from "@/models/search.model";
import { Categories } from "@/models/enums.model";
import { Testata } from "@/models/testata.model";
import { Sections } from "@/models/enums.model";
import MetaInfo from "vue-meta";
import EventBus from "@/services/event.bus";
import { EsService } from "@/services/es.service";
import { Picklist } from "@/models/picklist.model";

@Component({
  components: {
    JnJuranewsSearch,
    JnCardsStrip,
    JnSearchResults
  },
  metaInfo(this: JnCodici): MetaInfo {
    return { title: "JuraNews - Codici" };
  },
})
export default class JnCodici extends Vue {
  testata?: Testata;

  searchInPageKeywords = "";

  elencoCodici: Picklist[] = [];
  optCodice = new Picklist({ value: "", label: "" });

  numArticle = "";

  searchInPage() {
    EventBus.$emit("search-in-page", this.searchInPageKeywords);
  }

  codiciSearchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    size: 100,
    withText: true,
    perPage: 100,
    idCategory: [Categories.CODICI],
  });

  async created() {
    this.testata = JnService.getTestata(Sections.CODICI);
    const elencoCodiciRaw = JSON.parse(
      await EsService.getOptions("elenco-dei-codici")
    );
    for (const c of elencoCodiciRaw) {
      this.elencoCodici.push(new Picklist({ value: c.id, label: c.value }));
    }
    this.optCodice = this.elencoCodici[0];
  }

  goToCodice() {
    if (this.numArticle && this.numArticle != "") {
      this.$cookies.set("selectedArticle", this.numArticle.replaceAll(" ", ""));
    }
    const routeData = this.$router.resolve({
      name: "JuranetDoc",
      params: {
        id: this.optCodice!.value,
      },
    });
    window.open(routeData.href, "_blank");
  }
}
